$(function() {
  var height = $(document).height();
  
  $('a.popup_opleidingen').fancybox({
	'type' : 'ajax',
	'autoSize' : false,
	'margin' : [20,20,20,20],
    'padding' : 0,
    'width' : 'auto',
    'height' : '100%',
	'max-height': '100%',
	'autoCenter' : false,
    'hideOnContentClick' : false,
    'easingIn' : 1000,
    'easingOut': 1000,
    'easingChange' : 1000,
    'zoomSpeedIn' : 1000,
    'zoomSpeedOut' : 1000,
    'overlayOpacity' : 0.8,
    'centerOnScroll' : false,
  'backFocus' : false
  });

  $('#algemeneinfo').click(function(){
	$(this).siblings().toggle();
  });

  $(".data-href").click(function() {
      window.location = $(this).data("href");
  });

  
});

